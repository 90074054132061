/* eslint-disable no-underscore-dangle */
import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { WhiteLabelProvider } from '@/providers/WhiteLabelProvider';
import { isDev, isPrimaryProduct, isWidget } from './utils/domain';
import * as Sentry from '@sentry/react';

const Router = lazy(() => import('./routes/Router'));
const IframeLink = lazy(() => import('./pages/IframeLink'));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// for testing locally
const enableDevSentry = false;

if ((!isDev && !isWidget && isPrimaryProduct) || enableDevSentry) {
  const replayInstance = Sentry.replayIntegration({
    networkDetailAllowUrls: [
      /^https:\/\/xin\.gpt-trainer\.com\/api\//,
      /^https:\/\/app\.gpt-trainer\.com\/api\//,
    ],
    networkRequestHeaders: ['X-Custom-Header'],
    networkResponseHeaders: ['X-Custom-Header'],
    maskAllText: false,
    maskAllInputs: false,
    blockAllMedia: false,
  });

  Sentry.init({
    enabled: true,
    dsn: 'https://e165d0f6fdabb3f34726dea8d75a2aad@o4508677111414784.ingest.us.sentry.io/4508677115084800',
    integrations: [Sentry.browserTracingIntegration(), replayInstance],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/xin\.gpt-trainer\.com/, /^https:\/\/app\.gpt-trainer\.com/],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // allow session to collect first 15 minutes
  // after that stop replay
  setTimeout(
    async () => {
      if (replayInstance) {
        await replayInstance.stop();
      }
    },
    15 * 60 * 1000,
  );
}

root.render(
  <React.StrictMode>
    <WhiteLabelProvider>{isWidget ? <IframeLink /> : <Router />}</WhiteLabelProvider>
  </React.StrictMode>,
);
