/* eslint-disable import/prefer-default-export */

// following WIX iframe pattern we need
// 1. repalce all dots with dashes
// 2. add .filesusr.com at the end of domain
const generateWixDomain = (domain: string) => {
  if (!domain.startsWith('www')) {
    domain = `www.${domain}`;
  }

  domain = domain.replace(/\./g, '-');

  if (!domain.endsWith('.filesusr.com')) {
    domain += '.filesusr.com';
  }

  return domain;
};

// domain validation
// extract lvl from provided user domain and check with the same amount of lvls in hosted
export const isDomainValid = (parentDomain: string, domains: string[], isWix: boolean) => {
  return domains.some((domain) => {
    const domainToCheck = isWix ? generateWixDomain(domain) : domain;
    const lvlAmount = (domainToCheck.match(/\./g) || []).length;
    const extractedDomain = parentDomain
      .split('.')
      .slice(-(lvlAmount + 1))
      .join('.');
    return extractedDomain === domainToCheck;
  });
};

export const isPrimaryProduct = [
  '127.0.0.1',
  'localhost',
  'xin.gpt-trainer.com',
  'app.gpt-trainer.com',
].includes(window.location.hostname);

export const isDev = import.meta.env.MODE === 'development';

export const isDevDomain = /(xin\.gpt-trainer\.com|localhost|127\.0\.0\.1)/.test(window.location.hostname);

export const isWidget = window.location.pathname.startsWith('/widget');

export const currentPrimaryDomain = isDev ? 'xin.gpt-trainer.com' : 'app.gpt-trainer.com';

export const defaultIntegrationDomain = isPrimaryProduct ? currentPrimaryDomain : window.location.hostname;
